<template>
    <v-dialog
      v-model="shower"
      persistent
      :max-width="widthDialog"
      style="overflow-x: hidden"
    >
      <v-card>
        <v-card-title class="teal darken-3" dark>
          <span
            class="font-weight-medium white--text text-center"
            style="font-size: 18px"
          >
          {{ $t("verifyOTP") }}
          </span>
          <v-flex class="d-flex justify-end">
            <v-btn 
            large 
            color="grey"
            icon
            @click="$emit('close')"
            >
            <v-icon>
             mdi-close-circle
            </v-icon>
            </v-btn>
          </v-flex>
        </v-card-title>
  
        <v-card-text class="mt-6 pt-3 text-center">
          <v-form ref="form">
            <span class="text-subtitle-2 font-weight-black text-center">
              &nbsp;&nbsp; {{ $t("otp_botonebox.notelephone") }} </span
            ><br />
          </v-form>
         
        </v-card-text>
        <!-- <v-card-actions> -->
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn
            depressed
            color="error"
            class="text-button"
            :style="btnAction"
            :width="widthResendBtn"
            @click="closeDialog"
            :disabled="isloading"
          >
            <span> {{ $t("otp_botonebox.cancel_otp_btn") }} </span>
          </v-btn> -->
          <div class="text-center mt-3">
          <v-btn
            depressed
            class="text-button"
            color="teal"
            :dark="isloading ? false : true"
            :style="btnAction"
            @click="gotooneid"
            :disabled="isloading"
          >
            <span> {{ $t("otp_botonebox.setphonenumber") }} </span>
          </v-btn>
          </div>
          <br/>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import OtpInput from "otp-input-vue2";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import formatDatetime from "../../globalFunctions/formatDatetime";
  import VueCookies from "vue-cookies";
  import CryptoJS from "crypto-js";
  import axios from "axios";
  import { log } from "console";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000
  });
  
  export default {
    mixins: [validationMixin],
    validations: {
    },
    components: {
    },
    props: ["show", "data2fa"],
    computed: {
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      // Computed class or style

      heightDialog() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      widthResendBtn() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "50%";
          default:
            return "45%";
        }
      },
      widthDialog() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 340; // 340
          default:
            return 400;
        }
      },
      btnAction() {
        return "font-size: 16px; font-weight: lighter;";
      },

      shower: {
        get() {
          if (this.show === true) {
            // console.log("this.dataotp",this.dataotp );
            console.log("this.data2fa", this.data2fa);
            return this.show;
          }
        },
      },
    },
  
    data: function() {
      return {
        isloading:false,
      }
    },
    methods: {
        gotooneid(){
            window.open(this.data2fa.redirect)
        },
    },
    mounted() {},
   
  };
  </script>
  
  <style scoped>
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
  .vue-otp-input >>> .mai-classsed {
    text-align: center;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border: solid 1px rgba(128, 128, 128, 0.7);
    /*  */
  }
  .vue-otp-input >>> .error-class {
    border: solid 2px rgba(255, 125, 125, 255);
  }
  /* SCREEN SIZE XS  */
  @media screen and (max-width: 350px) {
    .vue-otp-input >>> .mai-classsed {
      width: 30px;
      height: 35px;
    }
  }
  @media (min-width: 350px) and (max-width: 370px) {
    .vue-otp-input >>> .mai-classsed {
      width: 35px;
      height: 35px;
    }
  }
  
  @media (min-width: 370px) and (max-width: 385px) {
    .vue-otp-input >>> .mai-classsed {
      width: 38px;
      height: 35px;
    }
  }
  
  @media screen and (min-width: 600px) {
    .vue-otp-input >>> .mai-classsed {
      width: 48px;
      height: 40px;
    }
  }
  </style>
  